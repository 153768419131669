import styled, { createGlobalStyle } from "styled-components";

const MainImage = styled.img`
  display: block;
  margin: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Main = styled.main`
  max-width: 800px;

  background-color: #ffd7d1;
  margin-top: -150px;
  font-family: "Yeon Sung", cursive;
`;

const Section = styled.div`
  margin-top: 48px;
`;

const List = styled.ul`
  & > li {
    margin-top: 16px;
  }
`;

const Padding = styled.div`
  padding: 48px;
`;

const Style = createGlobalStyle`
body {
  background-color: #ffd7d1;
    color: #420000;
}
`;

const Splash = styled.img`
  object-fit: cover;
  width: 100%;
  object-position: 0% 20%;
  height: 70vh;
`;

const Title = styled.h1`
  font-family: "Fredericka the Great", cursive;
  text-align: center;
  font-size: min(9rem, 13vw);
  letter-spacing: 2rem;
`;

export const Virka = () => {
  return (
    <>
      <Wrapper>
        <Splash src="https://i.imgur.com/Uz8fMW5.jpg" />
        <Style />
        <Main>
          <Padding>
            <Title>Virka</Title>
            <ul>
              <li>Species: Dragoness</li>
              <li>Age: 18 dragon years</li>
              <li>Height: 162 cm</li>
              <li>Weight: 68 kg</li>
            </ul>

            <Section>
              <h3>Where can I find her?</h3>
              <div>
                Virka's often roaming the wilds and dank caverns, looking for
                thrills. Though she's learned to live among civilized folks as
                well, she prefers the solitude of a forest or a mountain. She
                does not have a permanent lair, and often moves between various
                areas at whim.
              </div>
            </Section>
            <Section>
              <h3>Looks</h3>
              <div>
                <a href="https://i.imgur.com/hFyHPHA.jpg">This</a> and{" "}
                <a href="https://i.imgur.com/L8rQpna.jpg">this</a> represent her
                half-draconic form well, while{" "}
                <a href="https://i.imgur.com/BeZ6Cby.jpg">this</a> is a fine
                illustration of her more human form. Though she does, perhaps,
                have a fully draconic, feral form, it's been a long, long time
                since Virka used it. She feels just fine in the anthropomorphic
                body.
                <br />
                <br />
                The scales, in touch, feel much more like second skin, than
                actual draconic scales. They're warm, soft, a little more rough
                on the surface than naked skin, which leads to more friction.
                Moreover, they're far more resistant to abrasions, and they
                won't get sore until you try <i>really</i> hard.
              </div>
            </Section>
            <Section>
              <h3>Character</h3>
              <div>
                Energetic, lively, hotblooded; loves to risk and get excited.
                Ever curious, tends to sniff around in the bad places. Always on
                the move, hard to pin down. Quite often loud, talkative, blunt
                about her thoughts - tends to speak her mind openly, whether it
                might put her in trouble or not. Tends to think highly of
                herself and overestimate. Bit of a trickster and troublemaker.
                Loves good fun. <i>Hates</i> boredom. Will do just about
                anything to get things moving.
                <br />
                <br />
                Pretty smart, but it's not too hard to be smarter!
                <br />
                <br />
                Uppity - unless dominated. Though it is, definitely, possible to
                assert yourself over her, she certainly won't give up without a
                good bit of testing you and pushing limits.
              </div>
            </Section>

            <Section>
              <h3>Sexuality</h3>
              <div
                style={{
                  marginBottom: "16px",
                  border: "1px dashed orange",
                  padding: "8px",
                }}
              >
                <img
                  src="https://i.imgur.com/U7i7j4L.png"
                  width="100%"
                  style={{display:"block"}}
                  alt=""
                />
              </div>

              <div>
                Despite being quite young for a dragoness and not being raised
                by dragons, Virka's blood runs hot. While not overly
                experienced, she does feel a clear desire when a strong,
                powerful male dragon enters her view, even if a bit of awe and
                fear shadows the desire - for draconic beasts are far from
                gentle, and she knows well that a bit of desire might devolve
                into a hard, brutal romp that'll make her body sore before the
                male finishes.
                <br />
                <br />
                Moreover, Virka tends to be quite flirtatious, and sometimes
                downright blunt about her thoughts, mostly in one-on-one
                situations, where she feels herself way more 'free'. Guys she
                finds 'challenging', 'dangerous' and whatnot make the game more
                exciting for her.
                <br />
                <br />
                Although not apparent on the surface, Virka's body is far more
                resilient and enduring than that of a human female, which means
                she's generally well adjusted to mating with <i>most</i>{" "}
                dragons, and you'll have a hard time wearing her down, unless
                your beast is tough enough to last for hours and hours. Her
                loins are also more forgiving when it comes to large insertions,
                though - given overall she's still rather small - a fair share
                of dragons still is too big for her, an the intercourse wouldn't
                go without forceful breaking in, tons of moans and, possibly,
                pain - something she might actually enjoy, if the male is worth
                it.
                <br />
                <br />
                From time to time, she enjoys to play prey. Before a Hunter
                that's worth it, obviously.
              </div>
            </Section>
            <Section>
              <h3>What does she like?</h3>
              <div>
                Her animalistic likes are not much different from those of a
                wild dragoness. Above all, she values strong, powerful, virile
                males that know their way around and aren't afraid to show a bit
                of teeth, assert themselves and <i>dominate</i>.
                <br />
                <br />
                Though she's still exploring her sexuality, aggression's another
                thing that gets the dragoness going, especially if it's directed
                at her, in a sexual manner.
                <br />
                <br />
                She's really fond of seed, especially in rich, copious amounts.
                Whether <i>inside</i> or <i>outside</i>, it's about just
                arousing for the young dragoness. She takes it for a token of
                'maleness' of the male, of his virility and potency, which she,
                obviously, views as important and inseparable parts of a true
                male dragon.
              </div>
            </Section>

            <Section>
              <h3>Heat</h3>
              <div>
                Virka's body undergoes a year-long cycle. Though, no matter the
                time, the sight of a suitable male dragon is enough to get her
                fertile, two weeks in every year are special: she enters an
                estrus-like state that heightens her body's fertility, making
                her the ideal breeding mate, and forces her libido sky high. The
                sheer presence of a dragon then feels itchy, and she's quick to
                lose control once the male asserts his readiness to breed.
                <br />
                <br />
                And once the breeding starts, she wants to be bred <b>hard</b>.
              </div>
            </Section>

            <Section>
              <h3>About my likes - the writers's</h3>
              <div>
                <List>
                  <li>
                    I enjoy lengthy posts much more. I tend to write quite a bit
                    for most people's standards, and I'll expect you to do the
                    same. Two or three lines won't cut it.
                  </li>
                  <li>
                    I highly prefer the bestial, <i>dominant</i> kind of male
                    when it comes to <b>dragons</b>. They're <b>dominants</b>.
                    To me, dragons are powerful, aggressive, wild, bestial,
                    sexual-in-nature creatures with a lot of muscle to back it
                    up, and this is what makes them sexy to me. Hugging pillows
                    that pretend to be dragons won't find much luck here.
                  </li>
                  <li>
                    Continuing the last point - roleplays where one side{" "}
                    <i>dominates</i> the other are the most sexy to me, and it's
                    usually the male to dominate (though not always!). I like to
                    put Virka between the hammer and the anvil, in rough, wild,
                    brutal situations which she perhaps not fully willingly
                    caused herself, and that she's got to get through. She does
                    love to bite off more than she can chew, she loves to flirt
                    with the bad guys, she loves to have more than she can
                    handle, and this is my favorite kind of scene.
                  </li>
                  <li>
                    I write a lot about <i>sex</i> when it comes to dragons.
                    This should be pretty obvious by now. Non-sexual roleplays
                    fine only if I'm in the mood for one, which happens...
                    sometimes.
                  </li>
                </List>
              </div>
            </Section>
            <Section>
              <h3>Short ideas</h3>
              <div>
                A bunch of short ideas to expand upon or just play out.
                <br />
                <br />
                WIP! To be written.
                <List>
                  <li>Caught & pinned down in the wilds.</li>
                  <li>
                    The mating season begins; the first one for the Dragoness.
                    Forced by the instinct, she seeks out a male dragon, not
                    knowing what to expect. Unwittingly, she puts herself right
                    in the jaws of a true <i>beast</i>.
                  </li>
                </List>
              </div>
            </Section>
          </Padding>
        </Main>
      </Wrapper>
    </>
  );
};
