import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Err404 } from "./err404";
import { Natalia } from "./natalia/Natalia";
import { Virka } from "./virka";

export const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/n" exact component={Natalia} />
        <Route path="/vvvvvv" exact component={Virka} />
        <Route component={Err404} />
      </Switch>
    </BrowserRouter>
  );
};
