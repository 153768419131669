import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import reportWebVitals from './reportWebVitals';
import {App} from './App';

const GlobalStyles = createGlobalStyle`
body, html {
  margin: 0;
  padding: 0;
}
  body {

    font-family: 'Risque';
    
    font-size: 26px;
    line-height: 140%;
    letter-spacing: 0.2px;
  }

  img {
    display: inline-block;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles/>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
