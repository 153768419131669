import styled, { createGlobalStyle } from "styled-components";

const MainImage = styled.img`
  display: block;
  margin: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Main = styled.main`
  max-width: 700px;
  padding: 64px;
  border-width: 0 6px;
  border-style: dashed;
  border-color: #44000012;
`;

const Section = styled.div`
  margin-top: 48px;
`;

const List = styled.ul`
  & > li {
    margin-top: 16px;
  }
`;

const Style = createGlobalStyle`
body {
  background-color: #FFF1E6;
    color: #3C0000;
}
`;

export const Natalia = () => {
  return (
    <Wrapper>
      <Style />
      <Main>
        <MainImage width={600} src="https://i.imgur.com/5VX5jBe.png" alt="" />
        <h1 style={{ textAlign: "center" }}>Natalia</h1>
        <ul>
          <li>Species: Kitsune</li>
          <li>Age: 21 y/o</li>
          <li>Height: 169 cm</li>
          <li>Weight: 67 kg</li>
        </ul>

        <Section>
          <h3>Looks</h3>
          <div>
            She's pretty tan. Really tan. Lots of tan. And <i>curvy</i>. Doesn't
            smile often. She does like to moan, though. Go figure.
            <br />
            <br />
            That's all you need to know.
          </div>
        </Section>

        <Section>
          <h3>Character</h3>
          <div>
            Natalia’s quite the fierce fox. She’s energetic, easy to irritate
            and tends to go nuts whenever she doesn’t get what she wants. While
            she certainly might come off as stupidly selfish at first, she's the
            real friend once you've got her on your side. Then, she's far more
            loving and kind - and that might also be an understatement.
            <br />
            <br />
            This kitsune's also a bit of a mood-shifter - sometimes she's on a
            week-long streak of sitting on the couch and playing games.
            Sometimes she's far more adventurous, wandering far off into the
            wilds and seeking thrill. She likes to play it dangerous, for the
            best or for the worst.
            <br />
            <br />
            (I love to put her in pure fantasy worlds as well.)
          </div>
        </Section>

        <Section>
          <h3>Likes & Dislikes</h3>
          <List>
            <li>
              Getting what <b>she</b> wants. Seriously. Quite the selfish kitsy.
              Whether it's a fleeting whim or a deeper desire, she'll bite, nag,
              scratch and moan till she gets what she wants. Insufferably
              annoying and irresistibly charming.
            </li>
            <li>Cookies.</li>
            <li>
              ... and <i>dragons</i>.
            </li>
            <li>
              It's not that hard to get her to care about you. She'll hardly
              ever admit it though, and definitely not without a blush!
            </li>
            <li>
              ... and playing <i>games.</i>
            </li>
            <li>
              Natalia is fond of the bestial species, <b>dragons</b> in
              particular. She finds them far more exciting, and there's
              something quite interesting in creatures of the wild kind.
            </li>
            <li>... and se-- uh-, nevermind.</li>
            <li>
              Doesn't find 'soft', or overly romantic males too appealing,
              though mindless brutes aren't the thing either. The 'Hunter'
              personality is the most sexy.
            </li>
          </List>
        </Section>
        <Section>
          <h3>Sexual things</h3>
          Every girl's got her secrets, and Natalia's no different.
          <br />
          <br />
          She's a little too shy to write it all down here, so - squeeze it out
          of her yourself. Mind that she's really, <i>really</i> picky when it
          comes to males.
        </Section>
        <MainImage
          style={{ marginTop: "64px" }}
          src="https://i.imgur.com/3aCDc0l.png"
          width="60%"
        />
      </Main>
    </Wrapper>
  );
};
